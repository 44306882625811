<script setup lang="ts">
import { defineEmits, ref, onMounted, watch, defineProps, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  dialog: Boolean,
  loading: Boolean,
  type: {
    type: String as () => 'edit' | 'delete' | null,
    required: true,
  },
})

const { t } = useI18n()

const appointmentAnnulation = ref(true)

const emit = defineEmits(['update:confirm', 'update:close'])
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="500"
    transition="dialog-top-transition"
  >
    <v-card color="white" rounded="xl">
      <v-card-title
        class="d-flex pa-3 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="title font-weight-bold">{{
          type === 'delete' ? t('address.title') : t('address.edit_title')
        }}</span>

        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="emit('update:close')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-card-text class="">
        <div class="text-center d-flex flex-column ga-8 mx-auto pa-3">
          <v-img
            width="86.13"
            height="86.13"
            class="mx-auto d-flex align-center war-icon"
            src="@/assets/warning.png"
          />
          <div class="d-flex flex-column ga-2">
            <p class="text-gray-80 font-weight-bold" style="font-size: 16px">
              {{
                type === 'delete'
                  ? t('address.message', { value: 'supprimer' })
                  : t('address.message', { value: 'modifier' })
              }}
            </p>
            <p class="text-gray-80" style="font-size: 13px">
              {{
                type === 'delete'
                  ? t('address.description')
                  : t('dashboard.addresses.alertOnUpdate')
              }}
            </p>
          </div>
        </div>

        <div class="d-sm-flex align-center justify-center ga-sm-2 mt-3">
          <v-btn
            style="color: #757575"
            color="#E0E0E0"
            class="w-sm-33 d-flex justify-center mb-sm-0 mb-3 w-100 font-weight-bold py-5"
            @click="emit('update:close')"
            >{{ t('dashboard.subscription.AnnulerBtn') }}</v-btn
          >
          <v-btn
            color="white"
            :loading="loading"
            class="w-sm-66 w-100 bg-secondary py-5 d-flex justify-center"
            @click="emit('update:confirm')"
            >{{
              type === 'delete'
                ? t('confirm-delete', { value: 'suppression' })
                : t('confirm-delete', { value: 'modification' })
            }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-size: 23px !important;
  font-weight: 600px;
  line-height: 32.2px;
}

@media (max-width: 599px) {
  .title {
    font-size: 15px !important;
  }
}
</style>
