<template>
  <v-container fluid class="pa-0">
    <div class="d-flex justify-space-between ga-5">
      <div class="py-0" style="flex: 1">
        <template v-if="subscriptionStore.loadSubscription">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <SubscriptionInfo />
          <div v-if="mobile" class="my-5 d-flex flex-column ga-5">
            <ChangeSubscription color="#F5F5F5" />
            <!-- <v-btn
              @click="subscriptionStore.toggleSubscription"
              block
              color="secondary"
              >{{ $t('change_subscription') }}</v-btn
            > -->
          </div>
        </template>

        <template v-if="subscriptionStore.loadInformation">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <BillingInfo />
        </template>

        <template v-if="subscriptionStore.loadCard">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <PaymentMethode />
        </template>
      </div>

      <template v-if="!mobile && subscriptionStore.subscriptionStatus['type']">
        <SubscriptionPlan
          :selectedPlan="selectedPlan"
          :plans="SubscriptionData[selectedPlan]"
        />
      </template>
    </div>

    <v-row>
      <v-col cols="12">
        <template v-if="subscriptionStore.paymentListLoading">
          <div class="text-center py-5 font-italic">chargement...</div>
        </template>
        <template v-else>
          <Payments />
        </template>
      </v-col>
    </v-row>

    <ChangeSubscriptionDialog
      v-if="subscriptionStore.changeSubscription"
      @update:close="subscriptionStore.toggleSubscription"
    />
  </v-container>
</template>

<script lang="ts" setup>
import SubscriptionForm from '@/components/forms/subscription/SubscriptionForm.vue'
import { onMounted, ref, computed, Ref } from 'vue'
import { useAppStore } from '@/store/app'
import createHttpClient from '@/api/httpClient'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import SubscriptionRecap from '@/components/SubscriptionRecap.vue'
import BillingInfo from '@/components/subscription/BillingInfo.vue'
import PaymentMethode from '@/components/subscription/PaymentMethode.vue'
import SubscriptionInfo from '@/components/subscription/SubscriptionInfo.vue'
import Payments from '@/components/subscription/Payments.vue'
import MessageService from '@/components/feedback/message/messageService'
import { DashboardStatus } from '@/utils/dashboardStatus'
import { useUserStore } from '@/store/user/userStore'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import { Plan } from '@/shared/subscription'
import SubscriptionPlan from '@/components/SubscriptionPlan.vue'
import { SubscriptionData } from '@/shared/subscription'
import ChangeSubscription from '@/components/ChangeSubscription.vue'
import ChangeSubscriptionDialog from '@/components/dialog/ChangeSubscriptionDialog.vue'
import { useDisplay, useTheme } from 'vuetify'
import dayjs from 'dayjs'

const { mdAndDown, mobile } = useDisplay()

const subscriptionStore = useSubscriptionStore()
const userStore = useUserStore()
const { t: $t, d, n } = useI18n()
const appStore = useAppStore()
const subscriptionMonthsAlreadyPaid = ref(null)
const subscriptionMonthsAsked = ref(0)
const isLifetime = ref(false)

const selectedPlan = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['type']
  ) {
    if (subscriptionStore.subscriptionStatus['type'] == 'annually') {
      return 'annual'
    } else if (subscriptionStore.subscriptionStatus['type'] == 'life') {
      return 'lifetime'
    } else {
      return subscriptionStore.subscriptionStatus['type']
    }
  }
})

const userTva = useUserTvaStore()

const subscriptionStatus = ref({
  isActive: false,
  isCancel: false,
  isBreak: false,
})

const fetchInformation = async () => {
  const httpClient = createHttpClient()
  appStore.setInnerLoading(true)
  httpClient
    .get('/subscription/current-state')
    .then(response => {
      subscriptionMonthsAsked.value = response.data.totalMonthsAsked
      subscriptionMonthsAlreadyPaid.value = response.data.monthsAlreadyPaid
      isLifetime.value = !!response.data.isLifetime
      subscriptionStatus.value = { ...response.data.subscriptionStatus } // status des différents subscription d'un abonnement
    })
    .finally(() => {
      appStore.setInnerLoading(false)
    })
}

const countryTva = computed(() => {
  return subscriptionStore.subscriptionStatus['tva'] ?? userTva.countryTva?.tva
})

const canActive = ref()

const dashBoardState = async () => {
  const req = createHttpClient()

  const resp = await req.get('/account/dashboard-status')

  canActive.value = DashboardStatus(resp.data)
}

const plans = ref<Plan[]>()

onMounted(async () => {
  userTva.getUserTva()

  await subscriptionStore.fetchInformation() // fetch user informations details

  await subscriptionStore.fetchCardInfo() // fetch user card Detail

  await subscriptionStore.fetchSubscriptionStatus() // user subscription details

  dashBoardState()
})
</script>

<style lang="scss"></style>
