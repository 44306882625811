<template>
  <div class="d-flex flex-column grid-container-parent">
    <div
      align="center"
      class="date-time-g ga-2 grid-container d-flex flex-wrap align-center"
      no-gutters
    >
      <div class="d-flex ga-1 align-center">
        <v-icon class="a" icon="mdi-clock-outline" />

        <v-menu
          v-model="dateMenuOpen"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              variant="text"
              class="select-date b text-capitalize d-flex align-center py-1 cursor-pointer"
              v-bind="props"
            >
              <span class="text-black">{{ formattedSelectedDate }}</span>
              <v-icon
                class="transition-smooth"
                :class="{ 'rotated-angle': dateMenuOpen }"
                color="black"
                icon="mdi-chevron-down"
              />
            </v-btn>
          </template>
          <v-date-picker
            show-adjacent-months
            hide-header
            @update:model-value="handleSelectDate"
          ></v-date-picker>
        </v-menu>
      </div>

      <div class="d-flex ga-1 align-center">
        <span class="c">{{ $t('dashboard.time-slot.from') }}</span>
        <v-menu
          v-model="fromTimeMenuOpen"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              variant="text"
              class="select-start d d-flex align-center pr-2 py-1 cursor-pointer"
              v-bind="props"
            >
              <span class="text-black">{{ start }}</span>
              <v-icon
                class="transition-smooth"
                :class="{ 'rotated-angle': fromTimeMenuOpen }"
                color="black"
                icon="mdi-chevron-down"
              />
            </v-btn>
          </template>
          <v-time-picker
            v-model="start"
            :title="$t('timePicker.title')"
            color="green"
            format="24hr"
            @update:hour="start = $event + ':00'"
            @update:minute="start = start + ':' + $event"
          ></v-time-picker>
        </v-menu>
      </div>

      <div class="d-flex ga-1 align-center">
        <span class="e">{{ $t('dashboard.time-slot.to') }}</span>
        <v-menu
          v-model="toTimeMenuOpen"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              variant="text"
              class="select-end f d-flex align-center pr-2 py-1 cursor-pointer"
              :disabled="$props.endReadOnly"
              v-bind="props"
            >
              <span class="text-black">{{ end }}</span>
              <v-icon
                class="transition-smooth"
                :class="{ 'rotated-angle': toTimeMenuOpen }"
                color="black"
                icon="mdi-chevron-down"
              />
            </v-btn>
          </template>
          <v-time-picker
            v-model="end"
            color="green"
            :min="start"
            format="24hr"
            @update:hour="end = $event + ':00'"
            @update:minute="end = end + ':' + $event"
          ></v-time-picker>
        </v-menu>
      </div>
    </div>
    <div
      v-if="hasErrorDate"
      class="text-center text-caption text-red font-italic"
    >
      {{ $t('dashboard.time-slot.dialogTimeSlot.errorSelectedTimes') }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ModelRef, computed, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

const start: ModelRef<string> = defineModel('start')
const date: ModelRef<string> = defineModel('date')
const end: ModelRef<string> = defineModel('end')

defineProps({
  endReadOnly: {
    type: Boolean,
    default: false,
  },
})

const dateMenuOpen = ref(false)
const fromTimeMenuOpen = ref(false)
const toTimeMenuOpen = ref(false)
const formattedSelectedDate = computed(() => {
  const tempDate = dayjs(date.value, 'YYYY-MM-DD')
  const formattedDate = tempDate.locale('fr').format('dddd D MMMM')
  return formattedDate
})
const handleSelectDate = selectedDate => {
  date.value = dayjs(selectedDate).format('YYYY-MM-DD')
  dateMenuOpen.value = false
}
const emit = defineEmits(['update:start'])
watch(start, () => {
  emit('update:start', start.value)
})

const hasErrorDate = computed(() => {
  const startDate = dayjs(`${date.value} ${start.value}`, 'YYYY-MM-DD HH:mm')
  const endDate = dayjs(`${date.value} ${end.value}`, 'YYYY-MM-DD HH:mm')

  return endDate.isBefore(startDate) || endDate.isSame(startDate)
})
</script>
<style scoped>
@import url('./style.css');
@media (max-width: 540px) {
  .select-date,
  .select-end,
  .select-start {
    padding: 8px !important;
    font-size: 12px;
    border: 1px solid #aaa;
  }
  .date-time-g.ga-3.grid-container {
    justify-content: center;
  }
  .grid-container-parent {
    width: 100%;
  }
  .grid-container {
    display: grid;
    width: 100%;
    grid-template-areas:
      'a b b'
      'c d d'
      'e f f';
  }
  .a {
    grid-area: a;
  }
  .b {
    grid-area: b;
  }
  .c {
    grid-area: c;
  }
  .d {
    grid-area: d;
  }
  .e {
    grid-area: e;
  }
  .f {
    grid-area: f;
  }
}
</style>
