<script setup>
import { defineEmits, ref, onMounted, watch, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import countries from '@/shared/countries.json'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import createHttpClient from '@/api/httpClient'
import MessageService from '@/components/feedback/message/messageService'

const subscriptionStore = useSubscriptionStore()
const httpClient = createHttpClient()

const { t } = useI18n()
const emit = defineEmits(['update:company', 'update:close'])

const requiredRules = [v => !!v || `${t('dashboard.subscription.required')}`]
const loading = ref(false)

const { dialog, infos } = defineProps({
  dialog: Boolean,
  infos: {
    type: Object,
    required: true,
  },
})

const form = ref({
  nameCompany: '',
  siret: '',
  addressCompany: '',
  countryCompany: '',
  postalCodeCompany: '',
  cityCompany: '',
  tvaNumberCompany: '',
})

const isValidForm = ref(false)
const isInitializing = ref(false)

const onSubmit = async () => {
  if (!isValidForm.value) return false

  if (loading.value) return false
  loading.value = true

  try {
    const { data } = await httpClient.post(
      '/account/billing-informations',
      form.value,
    )
    MessageService.success(
      t('dashboard.subscription.messages.informations.success'),
    )
    emit('update:close')
    await subscriptionStore.fetchInfoData()
    await subscriptionStore.fetchSubscriptionStatus() // fetch subscription status
  } catch (error) {
    MessageService.error(
      $t('dashboard.subscription.messages.informations.error'),
    )
  } finally {
    loading.value = false
  }
}

const tva = ref(0)

watch(
  () => dialog,
  newVal => {
    isInitializing.value = true

    setTimeout(() => {
      isInitializing.value = false
    }, 0)
  },
  { immediate: true },
)

watch(
  () => form.value.countryCompany,
  async () => {
    if (isInitializing.value) return false
    if (form.value.tvaNumberCompany) {
      tva.value = 0
    } else {
      tva.value = await subscriptionStore.tvaByCountry(
        form.value.countryCompany,
      )
    }
  },
)

watch(
  () => form.value.tvaNumberCompany,
  async () => {
    if (isInitializing.value) return false
    if (form.value.tvaNumberCompany) {
      tva.value = 0
    } else {
      tva.value = await subscriptionStore.tvaByCountry(
        form.value.countryCompany,
      )
    }
  },
  { flush: 'post' },
)

onMounted(() => {
  if (!subscriptionStore.userInformation) return
  form.value.nameCompany = subscriptionStore.userInformation.nameCompany
  form.value.countryCompany = subscriptionStore.userInformation.countryCompany
  form.value.siret = subscriptionStore.userInformation.siret
  form.value.addressCompany = subscriptionStore.userInformation.addressCompany
  form.value.tvaNumberCompany =
    subscriptionStore.userInformation.tvaNumberCompany
  form.value.cityCompany = subscriptionStore.userInformation.cityCompany
  form.value.postalCodeCompany =
    subscriptionStore.userInformation.postalCodeCompany

  tva.value = subscriptionStore.subscriptionStatus['tva']
})
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card color="white" rounded="xl">
      <v-card-title
        class="py-3 d-flex justify-center w-full align-center bg-surface-lighten-1"
      >
        <span class="font-weight-bold title">{{
          t('dashboard.subscription.update_information_modal_title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="emit('update:close')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-form @submit.prevent="onSubmit" v-model="isValidForm">
        <v-card-text class="pa-8">
          <div>
            <label>{{ t('dashboard.subscription.company_label') }}</label>
            <v-text-field
              rounded="lg"
              density="compact"
              v-model="form.nameCompany"
            ></v-text-field>
          </div>

          <div>
            <label>{{ t('dashboard.subscription.siret') }}</label>

            <v-text-field
              rounded="lg"
              density="compact"
              v-model="form.siret"
            ></v-text-field>
          </div>

          <div>
            <div class="d-flex align-center">
              <label>{{ t('dashboard.subscription.tva_number') }}</label>
              <v-tooltip :text="$t('register.tva_infobulle')">
                <template v-slot:activator="{ props }">
                  <v-icon
                    size="x-small"
                    class="cursor-pointer"
                    color="#757575"
                    v-bind="props"
                    >mdi-information</v-icon
                  >
                </template>
              </v-tooltip>
            </div>
            <v-text-field
              rounded="lg"
              density="compact"
              v-model="form.tvaNumberCompany"
            ></v-text-field>
          </div>

          <div class="d-sm-flex ga-sm-2">
            <div class="w-sm-50 w-100">
              <label>{{ $t('dashboard.addresses.selectCountry') }}</label>
              <v-autocomplete
                rounded="lg"
                density="compact"
                class="mb-5"
                v-model="form.countryCompany"
                :items="countries"
                :rules="requiredRules"
                clearable
                hide-details
              />
            </div>

            <div class="w-sm-50 w-100">
              <label>% de TVA</label>

              <v-text-field
                readonly
                rounded="lg"
                v-model="tva"
                class="text-disabled"
                density="compact"
              />
            </div>
          </div>

          <div class="">
            <label>{{ t('register.billing_address') }}</label>
            <v-card color="white" elevation="0" border>
              <v-card-text border class="pa-0">
                <div class="pa-3">
                  <input
                    class="pa-0 no-focus w-100"
                    :placeholder="$t('register.billing_address_placeholder')"
                    v-model="form.addressCompany"
                  />
                </div>
                <v-divider />
                <div class="d-sm-flex address">
                  <div class="w-sm-50 border-custom pa-3">
                    <input
                      class="pa-0 no-focus w-100"
                      :placeholder="$t('register.postal_code')"
                      v-model="form.postalCodeCompany"
                    />
                  </div>
                  <div class="w-sm-50 pa-3">
                    <input
                      class="pa-0 no-focus w-100"
                      :placeholder="$t('register.city')"
                      v-model="form.cityCompany"
                    />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="d-flex justify-end mt-5">
            <v-btn
              :loading="loading"
              type="submit"
              color="white"
              class="bg-secondary px-5"
              >{{ t('dashboard.subscription.saveBtn') }}</v-btn
            >
          </div>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.border-custom {
  border-right: 1px solid #ccc;
}

.no-focus:focus {
  outline: none;
  box-shadow: none;
}
.border-custom {
  border-right: 1px solid #ccc;
}

@media (max-width: 599px) {
  .border-custom {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
</style>
