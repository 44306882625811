<script setup lang="ts">
import { onMounted, computed, ref } from 'vue'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import createHttpClient from '@/api/httpClient'
import { SubscriptionData } from '@/shared/subscription'

const { t } = useI18n()

const subscriptionStore = useSubscriptionStore()
const httpClient = createHttpClient()

const choice = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['type']
  ) {
    let currentSubscription = ''

    if (subscriptionStore.subscriptionStatus['type'] === 'annually') {
      currentSubscription = 'annual'
    } else if (subscriptionStore.subscriptionStatus['type'] === 'life') {
      currentSubscription = 'lifetime'
    } else {
      currentSubscription = subscriptionStore.subscriptionStatus['type']
    }

    return currentSubscription
  }
})

onMounted(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['tva']
  ) {
    subscriptionStore.asignPlanChoise(
      SubscriptionData[choice.value][0],
      subscriptionStore.subscriptionStatus['tva'],
    )
  }
})

const nextBillDate = computed(() => {
  if (!subscriptionStore.subscriptionStatus['nextBillDate']) return false
  return subscriptionStore.subscriptionStatus['nextBillDate']
})

// const monthsRemaining = computed(() => {
//   const currentDate = dayjs()

//   if (!subscriptionStore.subscriptionStatus['discountEndDate']) return

//   const endDate = dayjs(subscriptionStore.subscriptionStatus['discountEndDate'])

//   const months_remaining = endDate.diff(currentDate, 'month', true)

//   return Math.ceil(months_remaining)
// })

const discountMonthsRemaining = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['discountMonthsRemaining']
  ) {
    return subscriptionStore.subscriptionStatus[
      'discountMonthsRemaining'
    ] as number
  }
})

const discountAmount = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['discount']
  ) {
    return subscriptionStore.subscriptionStatus['discount']
  }
})

const nextAmountBill = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['actualPrice']
  ) {
    // is trialing period
    if (
      subscriptionStore.trialRemainingDays &&
      subscriptionStore.trialRemainingDays > 0
    ) {
      return (
        subscriptionStore.subscriptionStatus['actualPrice'] ??
        subscriptionStore.planChoise['price']
      )
    } else {
      // n'est plus en période d'essai
      // beneficie encore des mois de réduction
      if (
        subscriptionStore.subscriptionStatus['discountMonthsRemaining'] &&
        (subscriptionStore.subscriptionStatus[
          'discountMonthsRemaining'
        ] as number) > 0
      ) {
        return subscriptionStore.subscriptionStatus['actualPrice']
      } else {
        return subscriptionStore.subscriptionStatus['price']
      }
    }
  }
})

const tva = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['tva']
  ) {
    return subscriptionStore.subscriptionStatus['tva']
  }
})

const monthsAlreadyPaid = computed(() => {
  if (
    subscriptionStore.subscriptionStatus &&
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid']
  ) {
    return subscriptionStore.subscriptionStatus['monthsAlreadyPaid']
  }
})

const life_msg = computed(() => {
  const manualLifePay =
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid']
  if (
    subscriptionStore.alreadyPay ||
    (subscriptionStore.subscriptionStatus['type'] === 'life' &&
      manualLifePay === 40)
  ) {
    return t('life_msg')
  } else {
    return t('life_msg_unpaid')
  }
})
</script>

<template>
  <div class="subscription-info w-100">
    <div class="title d-flex align-center justify-space-between pa-2">
      <div>
        <span class="title-1 font-weight-medium"
          >{{ t('have_subscribe_to') }} :
        </span>
      </div>
      <div class="text-right">
        <span class="title-2 font-weight-medium"
          >{{ t('holitime_ref') }} -
          {{ t(`${subscriptionStore.subscriptionStatus['type']}`) }}</span
        >
      </div>
    </div>

    <!-- abonnement a vie  -->

    <div
      v-if="subscriptionStore.subscriptionStatus['type'] === 'life'"
      class="d-flex flex-column pt-2 ga-5"
    >
      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <template v-if="life_msg">
          <span v-html="life_msg"></span>
        </template>
        <span class="text-gray-800 font-weight-bold text-right">-</span>
      </div>

      <!-- <div
        v-if="nextBillDate"
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_bill_date') }}:</span>
        <span class="text-gray-800 font-weight-bold">{{
          dayjs(nextBillDate).format('D MMMM YYYY')
        }}</span>
      </div> -->

      <!-- <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_payment_amount') }}:</span>
        <span class="text-gray-800 font-weight-bold"
          >{{ nextAmountBill }} € {{ tva > 0 ? 'TTC' : 'H.T' }}</span
        >
      </div> -->
    </div>

    <!-- monthly pre-subscription user avec monthsAlreadyPaid > 1  -->
    <div
      v-else-if="
        subscriptionStore.subscriptionStatus['type'] === 'monthly' &&
        subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 1
      "
      class="d-flex flex-column pt-2 ga-5"
    >
      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span
          v-html="t('presubscribe_msg', { value: monthsAlreadyPaid })"
        ></span>
        <!-- <span
          v-if="subscriptionStore.subscriptionStatus['discountMonthsRemaining']"
          class="text-gray-800 font-weight-bold"
          >{{
            t('months_remaining', {
              value:
                subscriptionStore.subscriptionStatus['discountMonthsRemaining'],
            })
          }}</span
        > -->
      </div>

      <div
        v-if="nextBillDate"
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_bill_date') }}:</span>
        <span class="text-gray-800 font-weight-bold">{{
          dayjs(nextBillDate).format('D MMMM YYYY')
        }}</span>
      </div>

      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_payment_amount') }}:</span>
        <span class="text-gray-800 font-weight-bold"
          >{{ nextAmountBill }} € {{ tva > 0 ? 'TTC' : 'H.T' }}</span
        >
      </div>
    </div>

    <!-- bloc mensuel avec monthsAlreadyPaid = 0 et annuel ici  -->
    <div v-else class="d-flex flex-column pt-2 ga-5">
      <div
        v-if="discountMonthsRemaining && discountMonthsRemaining > 0"
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span v-html="t('discount_benefit', { value: discountAmount })"></span>
        <span class="text-gray-800 font-weight-bold text-right">{{
          t('months_remaining', { value: discountMonthsRemaining })
        }}</span>
      </div>

      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
        v-if="subscriptionStore.subscriptionStatus['trial']"
      >
        <span>{{ t('trial_period') }}:</span>
        <span
          v-if="
            subscriptionStore.trialRemainingDays &&
            subscriptionStore.trialRemainingDays > 0
          "
          class="text-gray-800 text-right font-weight-bold"
          >{{
            t('remaining_date', { value: subscriptionStore.trialRemainingDays })
          }}</span
        >
        <template v-else>
          <span
            class="text-gray-800 font-weight-bold text-right"
            v-html="
              t('remaining_date_pass', {
                value: dayjs(
                  subscriptionStore.subscriptionStatus['trial'],
                ).format('D MMMM YYYY'),
              })
            "
          ></span>
        </template>
      </div>

      <div
        v-if="nextBillDate"
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_bill_date') }}:</span>
        <span class="text-gray-800 font-weight-bold text-right">{{
          dayjs(nextBillDate).format('D MMMM YYYY')
        }}</span>
      </div>

      <div
        style="font-size: 14px"
        class="font-italic text-gray-80 d-flex justify-space-between"
      >
        <span>{{ t('next_payment_amount') }}:</span>
        <span class="text-gray-800 font-weight-bold text-right"
          >{{ nextAmountBill }} € {{ tva > 0 ? 'TTC' : 'H.T' }}</span
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.subscription-info .title {
  background-color: #ededed;
  font-size: 16px;
}

.title-2 {
  color: #029978;
}

.title-1 {
  color: #616161;
}

@media (max-width: 599px) {
  .subscription-info .title {
    font-size: 13px;
  }
}
</style>
